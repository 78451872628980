<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    placeholder="Pilih Salesman"
    allow-clear
    show-search
    :not-found-content="loading ? undefined : 'Tidak ditemukan'"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @change="onChange"
    @blur="resetSearch"
    @select="resetSearch"
    :loading="loading"
    v-bind="$attrs"
    :show-arrow="true"
    :disabled="disabled"
    :tag-render="customTagRender">
    <a-select-option v-for="d in results" :key="onFieldBidding(d)" :label="getLabel(d)">
      <a-tooltip :title="getLabel(d)">
        <span v-html="highlight(getLabel(d))" />
      </a-tooltip>
    </a-select-option>
    <template v-if="loading" #notFoundContent>
      <a-spin size="small" />
    </template>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { isEmpty, values, pickBy, debounce, merge, keyBy } from 'lodash'

export default {
  props: {
    value: {
      type: [Array, Number],
      default: () => [],
    },
    mode: {
      default: 'multiple',
      type: String,
    },
    distributor_id: {
      default: () => null,
      type: [String, Number],
    },
    vendor_id: {
      default: () => null,
      type: [String, Number],
    },
    useram_id: {
      default: () => null,
      type: [String, Number],
    },
    joinOptions: {
      type: [Array],
      default: () => [],
    },
  },
  emits: ['update:value', 'update:items'],
  setup(props, { emit }) {
    const results = ref([])
    const storage = ref([])
    const searchText = ref('')
    const { distributor_id, vendor_id, useram_id } = toRefs(props)
    const loading = ref(false)

    const fetchData = () => {
      loading.value = true
      results.value = []

      const apiURL = distributor_id.value
        ? `api/distributors/${distributor_id.value}/salesman`
        : '/api/filter/salesman'

      apiClient
        .get(apiURL, {
          params: pickBy({
            q: searchText.value,
            vendor_id: vendor_id.value,
            useram_id: useram_id.value,
          }),
        })
        .then(({ data }) => {
          const options = props.joinOptions.filter(item => item.id !== undefined)
          if (options.length > 0) {
            const keyedOptions = keyBy(options, 'id')
            const keyedData = distributor_id.value ? keyBy(data, 'salesman_id') : keyBy(data, 'id')
            data = values(merge(keyedOptions, keyedData))
          }

          results.value = data.sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          )

          if (isEmpty(storage.value)) {
            storage.value = results.value
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const getLabel = (item) => {
      return `${distributor_id.value ? item.salesman_id || '' : item.id || ''} - ${item.name || ''}`
    }

    const customTagRender = ({ label, closable, onClose }) => {
      return (
        <a-tooltip title={label}>
          <a-tag
            closable={closable}
            onClose={onClose}
            class="ant-select-selection-item-content"
            style="padding:0 8px;font-size: 14px;">
            {label}
          </a-tag>
        </a-tooltip>
      )
    }

    const onFieldBidding = (item) => {
      return distributor_id.value ? item.salesman_id || null : item.id || null
    }

    const highlight = value => {
      if (!searchText.value) return value
      const regex = new RegExp(searchText.value, 'gi')
      return value.replace(regex, match => `<span style="background-color: yellow;">${match}</span>`)
    }

    const resetSearch = () => {
      searchText.value = ''
    }

    const onSearch = debounce(value => {
      searchText.value = value
      fetchData()
    }, 300)

    const onChange = (value) => {
      const selectedItems = Array.isArray(value) ? value : [value]
      const selectedResults = storage.value.filter(item =>
        selectedItems.includes(onFieldBidding(item)),
      )
      emit('update:items', selectedResults)
    }

    onMounted(fetchData)

    watch([distributor_id, vendor_id, useram_id], fetchData)

    return {
      results,
      valueState: useVModel(props, 'value'),
      searchText,
      highlight,
      onSearch,
      onChange,
      onFieldBidding,
      loading,
      getLabel,
      customTagRender,
      resetSearch,
    }
  },
}
</script>
